<script setup lang="ts"></script>

<template>
  <div class="auth-layout">
    <slot />
  </div>
</template>

<style scoped lang="scss">
  .auth-layout {
    @include flex-container(column, center, center);
    width: 100%;
    height: 100%;
  }
</style>
